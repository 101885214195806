// extracted by mini-css-extract-plugin
export var neutralLogo = "relier-module--neutral-logo--bNPBN";
export var neutralLogoLink = "relier-module--neutral-logo-link--ohOQT";
export var neutralText = "relier-module--neutral-text--RgAkx";
export var relierAddress = "relier-module--relier-address---gpun";
export var relierBottomLogoImage = "relier-module--relier-bottom-logo-image--D3GnI";
export var relierContainer = "relier-module--relier-container--0NYMB";
export var relierImage = "relier-module--relier-image--Ob81r";
export var relierImageColumn = "relier-module--relier-image-column--ULBOU";
export var relierImageGrid = "relier-module--relier-image-grid--9+oD+";
export var relierImageText = "relier-module--relier-image-text--YAb-A";
export var relierLinkButton = "relier-module--relier-link-button--XTC-I";
export var relierLinkButtonGrid = "relier-module--relier-link-button-grid--8tKPV";
export var relierLogoImage = "relier-module--relier-logo-image--OX31J";
export var relierLogoSince = "relier-module--relier-logo-since--8tXCg";
export var relierLogoText = "relier-module--relier-logo-text--146xY";
export var relierLogoWrapper = "relier-module--relier-logo-wrapper--SuGcM";
export var relierPhone = "relier-module--relier-phone--ytp+j";