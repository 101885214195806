import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Helmet } from 'react-helmet'
import * as styles from '../../styles/insta-link/relier.module.css'

const Relier = () => {
  return (
    <div className={styles.relierContainer}>
      <Helmet title="Relier | 路地裏の小さな雑貨屋">
        <html lang="ja"/>
        <meta name="robots" content="index"/>
        <link rel="canonical" href="https://neutral-cc.com/insta-link/relier"/>
        <meta name="description" content="名古屋市中区新栄の雑貨屋「Relier（ルリエ）」です。ヴィンテージ食器・雑貨、アクセサリー、ヨーロッパ古着などを取り扱っています。予約制です。"/>
        <meta name="image" content="https://neutral-cc.com/static/f6234084be3b5b7800f0adda6d107d64/bdd7a/relier-web-shop.jpg"/>
        <meta property="og:locale" content="ja_JP"/>
        <meta property="og:site_name" content="Relier | 路地裏の小さな雑貨屋"/>
        <meta property="og:url" content="https://neutral-cc.com/insta-link/relier"/>
        <meta property="og:title" content="Relier | 路地裏の小さな雑貨屋"/>
        <meta property="og:description" content="名古屋市中区新栄の雑貨屋「Relier（ルリエ）」です。ヴィンテージ食器・雑貨、アクセサリー、ヨーロッパ古着などを取り扱っています。予約制です。"/>
        <meta property="og:image" content="https://neutral-cc.com/static/f6234084be3b5b7800f0adda6d107d64/bdd7a/relier-web-shop.jpg"/>
      </Helmet>
      <div className={styles.relierLogoWrapper}>
        <p className={styles.relierLogoText}>路地裏の小さな雑貨屋</p>
        <StaticImage
          className={styles.relierLogoImage}
          alt="Relier Web Shop"
          src="../../images/insta-link/relier/relier-logo.svg"
        />
        <p className={styles.relierLogoSince}>ー Since 2011 ー</p>
      </div>
      <div className={styles.relierLinkButtonGrid}>
        <a className={styles.relierLinkButton} href="https://www.instagram.com/relier_vintage/" target="_blank" rel="noopener noreferrer">
          ・Instagram・
        </a>
        <a className={styles.relierLinkButton} href="https://airrsv.net/relier-reserve/calendar" target="_blank" rel="noopener noreferrer">
          ・Reservation・
        </a>
        <a className={styles.relierLinkButton} href="https://reliermond.thebase.in/" target="_blank" rel="noopener noreferrer">
          ・Web shop・
        </a>
        <a className={styles.relierLinkButton} href="https://lin.ee/ZDHTMh8" target="_blank" rel="noopener noreferrer">
          ・LINE・
        </a>
      </div>
      <div className={styles.relierImageGrid}>
        <div className={styles.relierImageColumn}>
          <StaticImage
            className={styles.relierImage}
            alt="Relier Web Shop"
            src="../../images/insta-link/relier/relier-web-shop.jpg"
            imgStyle={{borderRadius: "5px"}}
          />
          <p className={styles.relierImageText}>
            ヴィンテージ食器・雑貨<br/>
            アクセサリー<br/>
            ヨーロッパ古着
          </p>
        </div>
        <div className={styles.relierImageColumn}>
          <StaticImage
            className={styles.relierImage}
            alt="Relier Entrance"
            src="../../images/insta-link/relier/relier-entrance.jpg"
            imgStyle={{borderRadius: "5px"}}
          />
          <p className={styles.relierImageText}>
            路地裏の小さな雑貨屋<br/>
            Relier (ルリエ) <br/>
            ＊予約制
          </p>
        </div>
      </div>
      <StaticImage
        className={styles.relierBottomLogoImage}
        alt="Relier Web Shop"
        src="../../images/insta-link/relier/relier-logo.svg"
      />
      <p className={styles.relierAddress}>名古屋市中区新栄2-39-26-2F</p>
      <p className={styles.relierPhone}>052-750-5188</p>
      <Link to="/" className={styles.neutralLogoLink}>
        <StaticImage
          className={styles.neutralLogo}
          alt="neutral"
          src="../../images/insta-link/relier/neutral-logo-white.png"
        />
      </Link>
      <p className={styles.neutralText}>Created by NEUTRAL</p>
    </div>
  )
}

export default Relier
